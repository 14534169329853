var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"模块编码","prop":"code","rules":[
  { required: true, message: '模块编码不能为空'},
  { pattern: /^[a-zA-Z]*$/, message: '请输入字母'}
]}},[_c('el-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.ruleForm.code),callback:function ($$v) {_vm.$set(_vm.ruleForm, "code", $$v)},expression:"ruleForm.code"}})],1),_c('el-form-item',{attrs:{"label":"模块名称","prop":"name","rules":[
  { required: true, message: '模块名称不能为空'}
]}},[_c('el-input',{model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"label":"排序","prop":"moduleSort","rules":[
  { required: true, message: '排序不能为空'},
  { type: 'number', message: '序号必须为数字值'}
]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.ruleForm.moduleSort),callback:function ($$v) {_vm.$set(_vm.ruleForm, "moduleSort", _vm._n($$v))},expression:"ruleForm.moduleSort"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.submitLabel))]),_c('el-button',{on:{"click":function($event){return _vm.handleSendClose()}}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }