<template>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <el-form-item label="模块编码" prop="code"     :rules="[
      { required: true, message: '模块编码不能为空'},
      { pattern: /^[a-zA-Z]*$/, message: '请输入字母'}
    ]">
            <el-input v-model="ruleForm.code" :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="模块名称" prop="name" :rules="[
      { required: true, message: '模块名称不能为空'}
    ]">
            <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="moduleSort" :rules="[
      { required: true, message: '排序不能为空'},
      { type: 'number', message: '序号必须为数字值'}
    ]">
            <el-input  v-model.number="ruleForm.moduleSort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">{{submitLabel}}</el-button>
            <el-button @click="handleSendClose()">取消</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    export default {
        components: {},
        props: {
            row: Object,
        },
        data() {
            return {
                ruleForm:{
                    name:'',
                    code:'',
                    moduleSort:1
                },
                submitLabel:"立即创建",
                disabled: false
            };
        },
        methods: {
            handleSendClose() {
                this.$emit("close",{});
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let postData = {
                            id:this.row.id,
                            code:this.ruleForm.code,
                            name:this.ruleForm.name,
                            sort:this.ruleForm.moduleSort
                        }
                        this.post(this.$api.moduleSet, postData);
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            post(url, data) {
                this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        this.$emit("refresh",{});
                        this.handleSendClose();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            },
            resetForm(formName) {
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                })
            },
            viewInit(){
                if (this.row && this.row.id){
                    this.disabled = true;
                    this.ruleForm.code=this.row.code;
                    this.ruleForm.name=this.row.name;
                    this.ruleForm.moduleSort=this.row.sort;
                    this.submitLabel="保存"
                }else{
                    this.disabled = false;
                    this.resetForm('ruleForm');
                    this.submitLabel="立即创建"
                }
            }
        },
        created() {
            this.viewInit();
        },
        watch:{
            row(){
               this.viewInit();
            }
        }
    };
</script>
<style lang="scss" scoped>
</style>