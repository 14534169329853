<template>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <el-form-item label="权限类型">
            <el-radio-group v-model="ruleForm.type" :disabled="disabled">
                <el-radio-button
                        v-for="item in options"
                        :key="item.value"
                        :label="item.value">
                    {{item.label}}
                </el-radio-button>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="模块ID" prop="moduleId">
            <el-input v-model="ruleForm.moduleId" disabled></el-input>
        </el-form-item>

        <el-form-item v-if="!disabled" label="父名称" prop="lastName">
            <el-input v-model="ruleForm.lastName" disabled></el-input>
        </el-form-item>

        <el-form-item label="父ID" prop="parentId">
            <el-input v-model="ruleForm.parentId" disabled></el-input>
        </el-form-item>


        <div v-if="ruleForm.type === 1">

            <el-form-item label="编码" prop="code">
                <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>

            <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称不能为空'}]">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>

            <el-form-item label="图标" prop="icon">
                <el-input v-model="ruleForm.icon"></el-input>
            </el-form-item>

            <el-form-item label="路由地址" prop="jump">
                <el-input v-model="ruleForm.jump"></el-input>
            </el-form-item>
        </div>

        <div v-if="ruleForm.type === 2">

            <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称不能为空'}]">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>

            <el-form-item label="接口地址" prop="url" :rules="[{ required: true, message: '接口地址不能为空'}]">
                <el-input v-model="ruleForm.url"></el-input>
            </el-form-item>

        </div>

        <div v-if="ruleForm.type === 3">

            <el-form-item label="编码" prop="code" :rules="[{ required: true, message: '编码不能为空'},{ pattern: /^([a-zA-Z0-9]|-)*$/, message: '输入编码不合法'}]">
                <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>

            <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称不能为空'}]">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>

            <el-form-item label="图标" prop="icon">
                <el-input v-model="ruleForm.icon"></el-input>
            </el-form-item>

        </div>

        <el-form-item label="备注" prop="remark">
            <el-input v-model="ruleForm.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="objSort" :rules="[{ required: true, message: '排序不能为空'},{ type: 'number', message: '序号必须为数字值'}]">
            <el-input  v-model.number="ruleForm.objSort" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">{{submitLabel}}</el-button>
            <el-button @click="handleSendClose()">关闭</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    export default {
        components: {},
        props: {
            row: Object,
            add: Boolean
        },
        data() {
            return {
                ruleForm:{
                    moduleId:'',
                    parentId:'',
                    type:1,
                    name:'',
                    code:'',
                    objSort:999,
                    jump:'',
                    icon:'',
                    url:'',
                    remark:''
                },
                options: [{
                    value: 1,
                    label: '菜单'
                }, {
                    value: 2,
                    label: '接口'
                }, {
                    value: 3,
                    label: '按钮'
                }],
                submitLabel:"立即创建",
                disabled: false
            };
        },
        methods: {
            handleSendClose() {
                this.$emit("close",{});
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let postData = this.ruleForm;
                        postData.sort = this.ruleForm.objSort;
                        this.post(this.$api.powerSet, postData);
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            post(url, data) {
                this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        this.$emit("refresh",{});
                        if(this.add){
                            this.viewInit();
                        }
                        // this.handleSendClose();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            },
            resetForm(formName) {
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                })
            },
            clearValidate(formName) {
                this.$nextTick(()=>{
                    this.$refs[formName].clearValidate();
                })
            },
            viewInit(){
                if (this.add){
                    this.disabled = false;
                    this.ruleForm.id = '';
                    this.ruleForm.type = 1;
                    this.ruleForm.moduleId=this.row.moduleId;
                    this.ruleForm.name = '';
                    this.ruleForm.code = '';
                    this.ruleForm.jump = '';
                    this.ruleForm.icon = '';
                    this.ruleForm.url = '';
                    this.ruleForm.remark = '';
                    this.ruleForm.objSort=999;
                    this.ruleForm.lastName=this.row.name;
                    if (this.row.type === 0){
                        this.ruleForm.parentId="0";
                    } else{
                        this.ruleForm.parentId=this.row.id;
                    }
                    this.submitLabel="立即创建";
                    this.clearValidate('ruleForm');
                }else{
                    this.disabled = true;
                    this.ruleForm.id = this.row.id;
                    this.ruleForm.type = this.row.type;
                    this.ruleForm.moduleId = this.row.moduleId;
                    this.ruleForm.parentId = this.row.parentId;
                    this.ruleForm.name = this.row.name;
                    this.ruleForm.code = this.row.code;
                    this.ruleForm.jump = this.row.jump;
                    this.ruleForm.icon = this.row.icon;
                    this.ruleForm.url = this.row.url;
                    this.ruleForm.remark = this.row.remark;
                    this.ruleForm.objSort=this.row.sort;
                    this.ruleForm.lastName='';
                    this.submitLabel="保存"
                }
            }
        },
        created() {
            this.viewInit();
        },
        watch:{
            row(){
               this.viewInit();
            },
            add(){
               this.viewInit();
            },
            'ruleForm.type': {
                handler() {
                    this.clearValidate('ruleForm');
                },
                immediate: true
            }
        }
    };
</script>
<style lang="scss" scoped>
</style>