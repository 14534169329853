<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item
                    >
                    <el-breadcrumb-item>系统</el-breadcrumb-item>
                    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
                    <el-breadcrumb-item>菜单列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-row>
            <!--树菜单-->
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>权限</span>
                        <div style="float: right;">
                            <el-button style="padding: 3px 0" type="text" @click="resetModuleSort">保存结构</el-button>
                            <el-button style="padding: 3px 0" type="text" @click="addModule">添加模块</el-button>
                        </div>
                    </div>
                    <el-tree
                            style="height:600px;overflow:auto"
                            draggable
                            :allow-drop="allowDrop"
                            :data="data"
                            :props="defaultProps"
                            node-key="id"
                            default-expand-all
                            :expand-on-click-node="false"
                            @node-click="handleNodeClick"
                    >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span>
                                <el-tag style="margin-right: 10px;" :type="tags[data.type].type" size="mini">
                                    {{tags[data.type].name}}
                                </el-tag>
                                <el-button
                                        type="text"
                                        size="mini"
                                        @click="append(data)">
                                    新增下级权限
                                </el-button>
                                <el-button
                                        :style="{'color':'rgb(245, 104, 104)'}"
                                        type="text"
                                        size="mini"
                                        @click="remove(data)">
                                    删除
                                </el-button>
                            </span>
                        </span>
                    </el-tree>
                </el-card>

            </el-col>
            <!--表单部分-->
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{actionTitle}}</span>
                    </div>
                    <module :row="row" v-if="moduleEditShow" @refresh="listData" @close="editClose"></module>
                    <power :row="row" :add="powerAdd" v-if="powerEditShow" @refresh="listData"
                           @close="editClose"></power>
                </el-card>
            </el-col>
        </el-row>
        <check :row="row" @dialog="editClose" v-if="checkShow"></check>
    </div>
</template>

<script>
    import module from "@/components/admin/permission/power/module.vue";
    import check from "@/components/admin/permission/power/check.vue";
    import power from "@/components/admin/permission/power/power.vue";

    export default {
        components: {
            module,
            check,
            power
        },
        props: {},
        data() {
            return {
                data: [],
                ruleForm: [],
                actionTitle: "详情",
                moduleEditShow: false,
                checkShow: false,
                powerEditShow: false,
                row: {},
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                tags: [
                    {name: '模块', type: ''},
                    {name: '菜单', type: 'success'},
                    {name: '接口', type: 'info'},
                    {name: '按钮', type: 'warning'}
                ],
                hasClick: false,
                powerAdd: false,
                expand: true
            };
        },
        methods: {
            listData() {
                this.$post(this.$api.powerList, {}).then((res) => {
                    this.data = res.data;
                });
            },
            //添加模块
            addModule() {
                this.editClose();
                this.row = {};
                this.actionTitle = '模块新增';
                this.moduleEditShow = true;
            },
            editClose() {
                this.moduleEditShow = false;
                this.checkShow = false;
                this.powerEditShow = false;
                this.actionTitle = "详情"
            },
            append(data) {
                this.editClose();
                this.hasClick = true;
                this.row = data;
                this.actionTitle = '权限新增';
                this.powerAdd = true;
                this.powerEditShow = true;
            },
            remove(data) {
                this.hasClick = true;
                this.row = data;
                this.checkShow = true;
            },
            handleNodeClick(data) {
                this.expand = false;
                if (!this.hasClick) {
                    this.editClose();
                    if (data.type === 0) {
                        this.row = data;
                        this.actionTitle = '模块详情';
                        this.moduleEditShow = true;
                    } else {
                        this.row = data;
                        this.actionTitle = '权限详情';
                        this.powerAdd = false;
                        this.powerEditShow = true;
                    }
                }
                this.hasClick = false;
            },
            allowDrop(draggingNode, dropNode) {
                if (dropNode.data.type === 0) {
                    return false;
                } else {
                    return true;
                }
            },
            resetModuleSort() {
                this.$post(this.$api.moduleReformat, this.data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            }
        },
        created() {
            this.listData();
        },
    };
</script>
<style>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
